import { Atom } from ":mods";
import { HomeCalloutSection } from "../../model";
import { HOME_HERO_SECTION } from "../../const";

export default function CalloutSection({ title, image, caption, person }: HomeCalloutSection) {
	const imgClass = "h-59px w-59px rounded-50% border-2 border#p border-solid";
	const buttons = HOME_HERO_SECTION.buttons;
	return (
		<section class="flex flex-col bg#paper items-center py-80px">
			<h1 class="w-803px mx-auto text-center text-48px font-bold leading-53px">{title} </h1>
			<div class="flex gap-20px mt-40px">
				{!!image?.src ? <img src={image.src} alt={image.alt} class={imgClass} /> : <div class={imgClass} />}

				<div>
					<article class="text-21px font-normal">{caption}</article>
					<p class="font-bold">- {person}</p>
				</div>
			</div>
			<div class="w-fit mt-40px font-bold">
        <Atom.Buttons.Button
              theme={buttons.tutor.theme as any}
              outlined={buttons.student.outlined as any}
              link={buttons.student.link}
              text={buttons.student.text}
            />
            </div>
		</section>
	);
}
